import Logger from '../helpers/logger';
import { getYearsAgo, getSimpleFormat } from '../helpers/date';
import { TextQuestionComponent, ValidationResult } from '@myriadgenetics/mgh-types';

const EMOJI_REGEX = '[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]';

const isNotEmptyString = (val: string) => typeof val === 'string' && val !== null && val.trim().length > 0;

const isBlankString = (val: string) => val === undefined || val === null || (typeof val === 'string' && val === '');

const isInteger = (val: number) => val === Math.floor(val);

const isValidAge = (val: number) => val === 0 || 1 === Math.sign(val);

const isValidMin = (min: number | undefined, num: number): boolean => {
  if (min === undefined) {
    return true;
  }
  return num >= min;
};

const isValidMax = (max: number | undefined, num: number): boolean => {
  if (max === undefined) {
    return true;
  }
  return num <= max;
};

const isValidStringForNumber = (value: string) => {
  return isNotEmptyString(value) || typeof value === 'number';
};

const isValidNumberValue = (num: number, min: number | undefined, max: number | undefined) => {
  return !isNaN(num) && num >= 0 && isInteger(num) && isValidAge(num) && isValidMin(min, num) && isValidMax(max, num);
};

const checkMatchesPattern = (val: string, pattern: string): boolean => {
  try {
    return new RegExp(pattern).test(val);
  } catch (error) {
    // TODO: handle error in a way so the user can take some sort of action
    // as this is a survey definition error and cannot be fixed by the user
    Logger.logError(error);
    return false;
  }
};

const checkPatternValidity = (val: string, pattern?: string): boolean => {
  const isValNotEmpty = isNotEmptyString(val);
  if (isValNotEmpty) {
    return pattern && isNotEmptyString(pattern) ? checkMatchesPattern(val, pattern) : isValNotEmpty;
  }
  return false;
};

const isLeapYear = (year: number): boolean => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

export enum ErrorMessage {
  ValidationRequired = 'validation.required',
  ValidationInvalid = 'validation.invalid',
}
interface TextQuestionValidationResult extends ValidationResult {
  errorMessage?: ErrorMessage;
}

type MaxDaysInMonth = {
  [month: number]: number;
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
  6: number;
  7: number;
  8: number;
  9: number;
  10: number;
  11: number;
  12: number;
};

export const validateTextQuestion = (tc: TextQuestionComponent, value: any, required: boolean): TextQuestionValidationResult => {
  const type = tc.data.inputType;
  let isValid = false;
  let errorMessage: ErrorMessage | null | undefined;

  const VALID_RESPONSE = {
    isValid: true,
    errorMessage: undefined,
  };

  const REQUIRED_RESPONSE = {
    isValid: false,
    errorMessage: ErrorMessage.ValidationRequired,
  };

  if (!required && isBlankString(value)) {
    return VALID_RESPONSE;
  }

  if (required && isBlankString(value)) {
    return REQUIRED_RESPONSE;
  }

  // TODO update secondaryResponse in text-question, validation, and option-select
  // I really don't like how we handle "secondaryResponse" - d$
  // we really should just create something around "notSure" specifically
  if (tc.data.secondaryResponse && tc.data.secondaryResponse.value === value) {
    return VALID_RESPONSE;
  }

  if (tc.data.secondaryResponse && Array.isArray(value)) {
    return VALID_RESPONSE;
  }

  const isValidDOB = (value: string) => {
    const separator = '-';
    const [year, month, day] = value.split(separator).map((n) => Number(n));
    const hasEnteredDate = year !== undefined && month !== undefined && day !== undefined;

    if (!hasEnteredDate) {
      return false;
    }

    const regexpDOB = /^([0-9]{2}[0-9]{2})-(1[0-2]|0?[1-9])-(3[01]|[12][0-9]|0?[1-9])$/;
    const isValidDOB = value.length === 10 && regexpDOB.test(value);

    if (!isValidDOB) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    const minMonth = 1;
    const maxMonth = 12;
    const minDay = 1;
    const minYear = 1900;

    const maxDaysInMonth: MaxDaysInMonth = {
      1: 31,
      2: isLeapYear(year) ? 29 : 28,
      3: 31,
      4: 30,
      5: 31,
      6: 30,
      7: 31,
      8: 31,
      9: 30,
      10: 31,
      11: 30,
      12: 31,
    };

    if (month < minMonth || month > maxMonth) {
      return false;
    }

    if (day < minDay || day > maxDaysInMonth[month]) {
      return false;
    }

    if (year < minYear || year > currentYear) {
      return false;
    }

    return true;
  };

  const isValidMRN = (val: string) => {
    const regexp = /^[A-Za-z0-9]*$/;
    const MIN_MRN_LENGTH = 4;
    const isValid = val.length >= MIN_MRN_LENGTH && regexp.test(val);
    return isValid;
  };

  switch (type) {
    case 'dob':
      isValid = isValidDOB(value);
      errorMessage = isValid ? undefined : ErrorMessage.ValidationInvalid;
      break;
    case 'date':
      const date = new Date(value); // TODO make more dynamic
      const dateTime = date.getTime();
      isValid =
        isNotEmptyString(value) &&
        date instanceof Date &&
        !isNaN(dateTime) &&
        dateTime >= Date.parse(getYearsAgo()) &&
        dateTime <= Date.parse(getSimpleFormat());
      errorMessage = isValid ? undefined : ErrorMessage.ValidationInvalid;
      break;
    case 'email':
      isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
      errorMessage = isValid ? undefined : ErrorMessage.ValidationInvalid;
      break;
    case 'tel':
      isValid = /[0-9]{3}-[0-9]{3}-[0-9]{4}/.test(value);
      isValid = isValid && value.length === 12;
      errorMessage = isValid ? undefined : ErrorMessage.ValidationInvalid;
      break;
    case 'postal':
    case 'address':
    case 'text':
      isValid = !checkPatternValidity(value, EMOJI_REGEX) && checkPatternValidity(value, tc.data.pattern);
      errorMessage = isValid ? undefined : ErrorMessage.ValidationInvalid;
      break;
    case 'name':
      isValid = !checkPatternValidity(value, EMOJI_REGEX) && checkPatternValidity(value, tc.data.pattern);
      errorMessage = isValid ? undefined : ErrorMessage.ValidationInvalid;
      break;
    case 'number':
    case 'age':
      const num = Number(value);
      isValid = isValidStringForNumber(value) && isValidNumberValue(num, tc.data?.min, tc.data?.max);
      errorMessage = isValid ? undefined : ErrorMessage.ValidationInvalid;
      break;
    case 'mrn':
      isValid = isValidMRN(value);
      errorMessage = isValid ? undefined : ErrorMessage.ValidationInvalid;
      break;
    default:
      isValid = false;
      errorMessage = ErrorMessage.ValidationInvalid;
  }

  return { isValid, errorMessage };
};
